import { isClient, isServer } from '@flyer/utils';
import { capitalize, toNumber } from 'lodash-es';
import localFont from 'next/font/local';
import { CefrLevel, WordDecision } from '@/__generated__/graphql';
import { ENVIRONMENT, HIDE_SCHOOL_DOMAINS } from '@/lib/config';

function getLocaleKey(keyName: string, locale?: string): string {
  return typeof locale === 'string' && locale === 'en'
    ? `${keyName}${capitalize(locale)}`
    : keyName;
}

export function getLocaleName(
  data: Record<string, unknown>,
  keyName: string,
  locale?: string,
): string {
  const name = data[getLocaleKey(keyName, locale)];

  return typeof name === 'string' ? name : '';
}

function getMediaUrl(url: undefined, type?: 'image' | 'audio' | 'avatar'): undefined;
function getMediaUrl(url: string, type?: 'image' | 'audio' | 'avatar'): string;
function getMediaUrl(url: string | undefined, type = 'image') {
  if (!url) {
    return;
  }

  if (url.startsWith('http')) {
    return url.replace('https://statics.flyer.vn', ENVIRONMENT.PUBLIC_BASE_MEDIA_URL);
  }

  return `${ENVIRONMENT.PUBLIC_BASE_MEDIA_URL}/${type}/${url}`;
}

export async function uploadAvatar(file: File | Blob, path = 'avatar') {
  const form = new FormData();
  form.append('file', file);
  if (!process.env.NEXT_PUBLIC_APP_API) {
    return null;
  }

  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_APP_API}/${path}`, {
      method: 'POST',
      body: form,
    });
    const json = (await response.json()) as { data: { id: string } };
    const {
      data: { id },
    } = json;

    return id;
  } catch {
    return null;
  }
}
export async function uploadAudio(audioFile: Blob) {
  if (!process.env.NEXT_PUBLIC_APP_API) {
    return null;
  }
  const formData = new FormData();
  formData.append('file', audioFile);
  const response = await fetch(`${process.env.NEXT_PUBLIC_APP_API}/record`, {
    method: 'POST',
    body: formData,
  });
  const json = (await response.json()) as { data: { id: string } };

  return json.data;
}

export const isScrollable = (ele: Element): boolean => {
  // Compare the height to see if the element has scrollable content
  const hasScrollableContent = ele.scrollHeight > ele.clientHeight;

  return hasScrollableContent;
};

export function domainSource() {
  if (isServer()) {
    return;
  }

  const fullUrl = window.location.hostname;
  const parts = fullUrl.split('.');

  return parts?.[0];
}

export const isFlyerUs = (): boolean =>
  isClient() && window?.location?.hostname?.endsWith('flyer.us');

export const isOnlyExamFlyerUs = (): boolean => false;
// isClient() && window?.location?.hostname?.includes('exam.flyer.us');

export const redisSet = (key: string, data: unknown, time?: number) => {
  return fetch('/exam/api/redis', {
    method: 'POST',
    body: JSON.stringify({
      key,
      data,
      time,
    }),
  });
};

export const cookieProfileId = process.env.NEXT_PUBLIC_COOKIE_PROFILE_ID as string;
export const cookieAnonymousProfileId = process.env
  .NEXT_PUBLIC_ANONYMOUS_LEARNER_PROFILE_ID as string;

export const urlMonthlyStripe = 'https://buy.stripe.com/8wM29s3Iy9SYcMg28d';
export const utmSourceId = 'utm_source';

export const utmMediumId = 'utm_medium';
export const utmCampaignId = 'utm_campaign';
export const utmContentId = 'utm_content';

// use for coupon code and referral code
export const mgmReferralCode = 'referral_code';
export default getMediaUrl;

export function isHideSchool() {
  return HIDE_SCHOOL_DOMAINS.includes(window.location.host);
}
export function fNumber(number: string | number) {
  return toNumber(number).toLocaleString('vi');
}

export const BalooBhaifont = localFont({
  src: '../../public/fonts/BalooBhai-Regular.ttf',
  variable: '--font-BalooBhai-Regular',
});

export const DinRoundProFont = localFont({
  src: [
    {
      path: '../../public/fonts/DinRoundPro/DINRoundPro-Bold.ttf',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../../public/fonts/DinRoundPro/DINRoundPro-Medi.ttf',
      weight: '500',
      style: 'normal',
    },
  ],
  variable: '--font-DINRoundPro',
});

export const getColorFromScore = (decision: WordDecision | undefined) => {
  if (!decision) {
    return {};
  }
  switch (decision) {
    case WordDecision.Correct: {
      return {
        color: '#7DB42E',
        linearColor: 'linear-gradient(270deg, #7DB42E 0%, #528B00 100%)',
      };
    }
    case WordDecision.Warning: {
      return {
        color: '#FFAC2F',
        linearColor: 'linear-gradient(270deg, #FFAC2F 0%, #E58900 100%)',
      };
    }
    case WordDecision.Incorrect: {
      return {
        color: '#CC2929',
        linearColor: 'linear-gradient(270deg, #CC2929 0%, #95001E 100%)',
      };
    }
    default: {
      return {
        color: '#7DB42E',
        linearColor: 'linear-gradient(270deg, #7DB42E 0%, #528B00 100%)',
      };
    }
  }
};

export const countWord = (text: string) => {
  const words = text.trim().split(/\s+/);

  return words.filter((word) => word.length > 0).length;
};

export const listLevelData = [
  {
    title: 'Pre A1',
    value: CefrLevel.Prea1,
  },
  {
    title: 'A1',
    value: CefrLevel.A1,
  },
  {
    title: 'A2',
    value: CefrLevel.A2,
  },
  {
    title: 'B1',
    value: CefrLevel.B1,
  },
  {
    title: 'B2',
    value: CefrLevel.B2,
  },
  {
    title: 'C1',
    value: CefrLevel.C1,
  },
  {
    title: 'C2',
    value: CefrLevel.C2,
  },
];
export const handlerSendToSlack = ({
  errorMessage,
  currentUserAttemptId,
  partId,
  userId,
  phone,
  email,
}: {
  errorMessage: string;
  currentUserAttemptId: string;
  partId?: string;
  userId?: string;
  phone?: string | null;
  email?: string | null;
}) => {
  // void fetch('https://hooks.slack.com/services/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', {
  //   method: 'POST',
  //   body: JSON.stringify({
  //     text: `message:${errorMessage}----userAttemptId:${currentUserAttemptId}----partId:${
  //       partId || ''
  //     }---userId:${userId || ''}---phone:${phone || ''}---email:${email || ''}`,
  //   }),
  // });
};
export const zaloLink = 'https://zalo.me/flyerschool';
export const WhatAppLink = 'https://api.whatsapp.com/send?phone=8485151197';

export const convertStyleStrToObject = (styleString: string) => {
  if (!styleString) return {};

  return styleString.split(';').reduce((styleObject, rule) => {
    const [property, value] = rule.split(':');
    if (!property || !value) return styleObject;
    return { ...styleObject, [property.trim()]: value.trim() };
  }, {});
};

export const SESSION_STORAGE_KEYS = {
  HAS_SHOW_DAILY_GIFT_MODAL: 'HAS_SHOW_DAILY_GIFT_MODAL',
  HAS_SHOW_COMPLETE_INFO_MODAL: 'HAS_SHOW_COMPLETE_INFO_MODAL',
};
