
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { graphql } from '@/__generated__';
import { AppTypeMobile } from '@/__generated__/graphql';
import { GetViewerAchievements } from '@/api/common';
import HomePage from '@/components/exam/ExamHomeView';
import { ModalCompleteInfoLazy, ModalGetRewardLazy, PlacementTestModalLazy, PlacementTestResultModalLazy, SummaryMonthModalLazy, SummaryYearModalLazy, WhatNewsModalLazy, } from '@/components/popups';
import { TestLevels, TestLevelsDocument, TestLevelsVariables } from '@/graphql/generated/query';
import useBreakpointValue from '@/hooks/useBreakpointValue';
import useUserLocalTest from '@/hooks/useUserLocalTest';
import { initializeApollo } from '@/lib/apollo';
import ROUTES from '@/lib/routes';
import { SESSION_STORAGE_KEYS } from '@/lib/utils';
import { useAuth } from '@/providers/auth';
import { profileViewRecapMonthlyAtom, profileViewRecapYearlyAtom, profileViewWhatNewsAtom, testLevelAtomResponse, timeUserStartVisitAtom, } from '@/store/home';
import { useQuery } from '@apollo/client';
import { useModal } from '@flyer/components';
import { isClient } from '@flyer/utils';
import time from '@flyer/utils/time';
import { useStatsigClient } from '@statsig/react-bindings';
import { useAtom, useAtomValue } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import mixpanel from 'mixpanel-browser';
import setLanguage from 'next-translate/setLanguage';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import TiktokPixel from 'tiktok-pixel';
import { BooleanParam, NumberParam, StringParam, useQueryParams } from 'use-query-params';
const ModalPriority = {
    WhatNews: 4,
    GetReward: 3,
    CompleteInfo: 1,
    PlacementTest: 2
};
async function getStaticProps() {
    const client = initializeApollo();
    const { data } = await client.query<TestLevels, TestLevelsVariables>({
        query: TestLevelsDocument
    });
    // const { data: checkPointPlacementTest } = await client.query<
    //   GetDataPlacementTest,
    //   GetDataPlacementTestVariables
    // >({
    //   query: GetDataPlacementTestDocument,
    //   variables: {
    //     where: {
    //       type: {
    //         _eq: 'PLACEMENT_TEST',
    //       },
    //     },
    //   },
    // });
    return { props: { data }, revalidate: 3600 * 24 };
}
const ViewerGetVersionNotificationUpdate = graphql(`
  query ViewerGetVersionNotificationUpdate($appType: AppTypeMobile!) {
    versionNotification(appType: $appType) {
      appType
      contentHTML
      contentHTMLEn
      id
      image {
        id
        url
      }
      status
      version
      versionNotificationId
      videoId
    }
  }
`);
export default function Home({ data }: {
    data: TestLevels;
}) {
    useHydrateAtoms([
        [testLevelAtomResponse, data],
        // [checkPointPlacementTestAtom, head(checkPointPlacementTest?.testLevels)?.check_points],
    ]);
    const router = useRouter();
    const { currentLearnerProfile, user, learnerProfiles, loading } = useAuth();
    const [openModalGetReward, setOpenModalGetReward] = useState(false);
    // const placementTestLevelId = data?.testLevels?.find((lv) => lv.slug === 'placement-test')?.id;
    const listProfileViewedRecapYear = useAtomValue(profileViewRecapYearlyAtom);
    // const [latestPlacementTestResponse] = useLazyQuery(GetDataPlacementTest, {
    //   context: {
    //     v2: true,
    //   },
    // });
    const { data: dataWhatNews, loading: loadingDataWhatNews } = useQuery(ViewerGetVersionNotificationUpdate, {
        variables: {
            appType: AppTypeMobile.ExamWeb
        },
        context: {
            v2: true
        },
        skip: !currentLearnerProfile?.id,
        fetchPolicy: 'cache-and-network'
    });
    const [timeUserStartVisit, setTimeUserStartVisit] = useAtom(timeUserStartVisitAtom);
    const [listProfileViewedRecap, setListProfileViewedRecap] = useAtom(profileViewRecapMonthlyAtom);
    const [userViewWhatsNews, setUserViewWhatsNews] = useAtom(profileViewWhatNewsAtom);
    const currentDay = time();
    const breakpoint = useBreakpointValue();
    const isOnMobile = useMemo(() => {
        if (!breakpoint)
            return true;
        return breakpoint === 'sm';
    }, [breakpoint]);
    const [q, setQ] = useQueryParams({
        modalPlacementTest: BooleanParam,
        modalPlacementTestResult: BooleanParam,
        checkpoint: StringParam,
        classcode: StringParam,
        placementTestId: StringParam,
        modalRecap: BooleanParam,
        monthlyRecap: StringParam,
        yearlyRecap: StringParam,
        modalWhatNews: BooleanParam,
        age: NumberParam
    });
    useEffect(() => {
        const timer = setTimeout(() => {
            const date = time().date();
            const previousMonth = time().subtract(1, 'month');
            if (!q.monthlyRecap && !q.yearlyRecap && date <= 7 && currentLearnerProfile?.id) {
                const checkExitShow = listProfileViewedRecap.filter((i) => i?.profileId === currentLearnerProfile?.id &&
                    i?.date === previousMonth.format('YYYY-MM'));
                if (checkExitShow.length === 0 &&
                    previousMonth.format('MM') !== '12' &&
                    previousMonth.format('MM') !== '01') {
                    setQ({
                        monthlyRecap: previousMonth.format('MM'),
                        yearlyRecap: previousMonth.format('YYYY')
                    });
                }
            }
        }, 500);
        return () => clearTimeout(timer);
    }, [
        currentLearnerProfile,
        currentLearnerProfile?.id,
        listProfileViewedRecap,
        q.monthlyRecap,
        q.yearlyRecap,
        setQ,
        isOnMobile,
    ]);
    useEffect(() => {
        if (isOnMobile) {
            setQ({ monthlyRecap: undefined, yearlyRecap: undefined });
        }
    }, [isOnMobile, setQ]);
    useEffect(() => {
        const timer = setTimeout(() => {
            const checkExitShow = listProfileViewedRecapYear.filter((e) => e?.profileId === currentLearnerProfile?.learnerProfileId && e?.year === '2024');
            const startDay = time('2025-01-01').startOf('day');
            const endDay = time('2025-01-10').endOf('day');
            const isBetWeen = currentDay.isBetween(startDay, endDay, null, '[]');
            if (currentLearnerProfile?.learnerProfileId &&
                checkExitShow.length === 0 &&
                isBetWeen &&
                !isOnMobile &&
                !loading &&
                !currentLearnerProfile?.isAnonymous &&
                ((learnerProfiles.length > 1 && time(timeUserStartVisit).diff(currentDay, 'hours') > 0) ||
                    learnerProfiles.length === 1)) {
                void router.push('/year-recap/2024');
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [
        currentDay,
        currentLearnerProfile?.isAnonymous,
        currentLearnerProfile?.learnerProfileId,
        isOnMobile,
        learnerProfiles?.length,
        listProfileViewedRecapYear,
        loading,
        router,
        timeUserStartVisit,
    ]);
    // logic what news
    // auto open
    useEffect(() => {
        if (dataWhatNews?.versionNotification?.version && userViewWhatsNews && user?.id) {
            const checkUserViewed = userViewWhatsNews?.find((check) => check.userId === user?.id &&
                check.appVersion === dataWhatNews?.versionNotification?.version);
            if (!checkUserViewed) {
                setQ({
                    modalWhatNews: true
                });
            }
        }
    }, [dataWhatNews?.versionNotification, setQ, user?.id, userViewWhatsNews]);
    const handlerCloseUserViewWhatNews = useCallback(() => {
        const checkUserViewed = userViewWhatsNews?.find((check) => check.userId === user?.id &&
            check.appVersion === dataWhatNews?.versionNotification?.version);
        if (!checkUserViewed && user?.id && dataWhatNews?.versionNotification?.version) {
            void setUserViewWhatsNews([
                ...userViewWhatsNews,
                {
                    userId: user?.id,
                    appVersion: dataWhatNews?.versionNotification?.version
                },
            ]);
        }
        setQ({ modalWhatNews: undefined });
    }, [
        dataWhatNews?.versionNotification?.version,
        setQ,
        setUserViewWhatsNews,
        user?.id,
        userViewWhatsNews,
    ]);
    useModal(SummaryMonthModalLazy, {
        open: Boolean(q.monthlyRecap) && Boolean(q.yearlyRecap) && !!currentLearnerProfile,
        year: q.yearlyRecap,
        month: q.monthlyRecap,
        onClose: () => {
            const checkExit = listProfileViewedRecap.filter((e) => e?.profileId === currentLearnerProfile?.id &&
                e?.date === `${q?.yearlyRecap as string}-${q?.monthlyRecap as string}`);
            if (currentLearnerProfile?.id && checkExit.length === 0 && q.yearlyRecap && q.monthlyRecap) {
                setListProfileViewedRecap((cur) => [
                    ...cur,
                    {
                        profileId: currentLearnerProfile.id,
                        date: `${q?.yearlyRecap as string}-${q?.monthlyRecap as string}`
                    },
                ]);
            }
            setQ({ monthlyRecap: undefined, yearlyRecap: undefined });
        }
    });
    useModal(PlacementTestModalLazy, {
        open: q.modalPlacementTest || false,
        onClose: () => setQ({ modalPlacementTest: undefined, age: undefined }),
        priority: ModalPriority.PlacementTest,
        age: q.age
    });
    useModal(PlacementTestResultModalLazy, {
        open: q.modalPlacementTestResult || false,
        onClose: () => setQ({ modalPlacementTestResult: undefined, placementTestId: undefined })
    });
    const openModalWhatNews = useMemo(() => (q.modalWhatNews && !!dataWhatNews?.versionNotification && !loadingDataWhatNews) || false, [dataWhatNews?.versionNotification, loadingDataWhatNews, q.modalWhatNews]);
    useModal(WhatNewsModalLazy, {
        open: openModalWhatNews,
        onClose: handlerCloseUserViewWhatNews,
        data: dataWhatNews?.versionNotification,
        priority: ModalPriority.WhatNews
    });
    // handler if exit class and
    useEffect(() => {
        if (currentLearnerProfile?.id &&
            currentLearnerProfile?.classStudent?.class?.code &&
            q.classcode &&
            currentLearnerProfile?.classStudent?.class?.code === q.classcode &&
            q.checkpoint) {
            void router.push(ROUTES.REDIRECT_TO_CHECKPOINT(q.checkpoint));
        }
    }, [
        currentLearnerProfile?.classStudent?.class?.code,
        currentLearnerProfile?.id,
        q.checkpoint,
        q.classcode,
        router,
    ]);
    //
    // const getData = useCallback(
    //   async (profile: LearnerProfileFragment | null | undefined) => {
    //     if (profile && placementTestLevelId) {
    //       const { data: lastPlacementTestResponse } = await latestPlacementTestResponse({
    //         variables: {
    //           learnerProfileId: profile.id,
    //           testLevelIds: [placementTestLevelId],
    //         },
    //         fetchPolicy: 'network-only',
    //       });
    //
    //       const idLatestPlacementTest = lastPlacementTestResponse?.learnerProfile?.latestAttempt?.id;
    //       setLastPlacementTestIdAtom(idLatestPlacementTest);
    //     }
    //   },
    //   [latestPlacementTestResponse, placementTestLevelId, setLastPlacementTestIdAtom],
    // );
    const isNeedSwitchProfile = useMemo(() => {
        return (!!user &&
            !!timeUserStartVisit &&
            time(timeUserStartVisit).diff(currentDay, 'hours') <= 0 &&
            !!currentLearnerProfile &&
            learnerProfiles.length > 1 &&
            (!!user.email || !!user?.phoneNumber));
    }, [currentDay, currentLearnerProfile, learnerProfiles.length, timeUserStartVisit, user]);
    // force user update school or choose profile
    const handleSwitchOrUpdateProfile = useCallback(() => {
        if (isNeedSwitchProfile) {
            void router.push({ pathname: '/profiles', query: { ...router.query } });
        }
        // if (
        //   !isNeedSwitchProfile &&
        //   !!currentLearnerProfile &&
        //   !currentLearnerProfile.school &&
        //   !isHideSchool()
        // ) {
        //   void router.push({ pathname: '/update-profile', query: { ...router.query } });
        // }
    }, [isNeedSwitchProfile, router]);
    const handleNoProfile = useCallback(() => {
        if (user && !currentLearnerProfile && (user.email || user?.phoneNumber)) {
            void router.push('/create-profile');
        }
    }, [currentLearnerProfile, router, user]);
    useModal(SummaryYearModalLazy, {
        open: q.modalRecap || false,
        onClose: () => {
            // modal year 2022
            setQ({ modalRecap: undefined });
        }
    });
    /* from 2023 -> convert to page for tracking and share
     - handler logic 2023
    */
    // useEffect(() => {
    //   void getData(currentLearnerProfile);
    // }, [getData, currentLearnerProfile]);
    const userLocalTest = useUserLocalTest();
    const openedPlacementTest = useRef(false);
    useEffect(() => {
        if (userLocalTest) {
            if (q.modalPlacementTest) {
                openedPlacementTest.current = true;
                return;
            }
            if (!openedPlacementTest.current) {
                openedPlacementTest.current = true;
                setQ({ modalPlacementTest: true });
                return;
            }
            return;
        }
        handleSwitchOrUpdateProfile();
    }, [handleSwitchOrUpdateProfile, q.modalPlacementTest, router, setQ, userLocalTest]);
    useEffect(() => {
        handleNoProfile();
    }, [handleNoProfile]);
    useEffect(() => {
        if (learnerProfiles.length > 1 && !timeUserStartVisit && currentLearnerProfile) {
            setTimeUserStartVisit(currentDay.add(24, 'hours').format());
        }
    }, [
        currentDay,
        currentLearnerProfile,
        setTimeUserStartVisit,
        timeUserStartVisit,
        learnerProfiles,
    ]);
    // hide scrollbar on mobile
    useEffect(() => {
        const styleEl = document.createElement('style');
        if (isOnMobile) {
            document.head.append(styleEl);
            styleEl.sheet?.insertRule('body { overflow: hidden; }');
        }
        return () => {
            styleEl.remove();
        };
    }, [isOnMobile]);
    const { client } = useStatsigClient();
    // tracking facebook
    useEffect(() => {
        if (isClient() && !loading) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            window.fbq('track', 'ExamHomePageView', {
                id: user?.id,
                displayName: currentLearnerProfile?.displayName
            });
            void TiktokPixel.track('ExamHomePageView', {
                id: user?.id,
                displayName: currentLearnerProfile?.displayName
            });
            mixpanel.track('ExamHomePageView', {
                pageView: 'ExamHomePageView'
            });
            void client.updateUserAsync({
                userID: user?.id,
                custom: {
                    displayName: currentLearnerProfile?.displayName
                }
            });
            client.logEvent('ExamHomePageView', 'user', {
                userID: user?.id || ''
            });
        }
    }, [
        loading,
        currentLearnerProfile?.displayName,
        user?.email,
        user?.id,
        user?.phoneNumber,
        client,
    ]);
    const { data: dataAchievements, loading: loadingListAchievements } = useQuery(GetViewerAchievements, {
        context: {
            v2: true
        },
        onCompleted: (res) => {
            if (res?.viewerAchievements?.some((i) => !i.seen) &&
                currentLearnerProfile?.id &&
                !openModalWhatNews) {
                setOpenModalGetReward(true);
            }
        },
        fetchPolicy: 'cache-and-network',
        skip: loadingDataWhatNews || !currentLearnerProfile?.id
    });
    // get Reward
    useModal(ModalGetRewardLazy, {
        open: openModalGetReward,
        onClose: () => setOpenModalGetReward(false),
        listDataAchievements: dataAchievements?.viewerAchievements,
        priority: ModalPriority.GetReward
    });
    const [openModalCompleteInfo, setOpenModalCompleteInfo] = useState(false);
    useEffect(() => {
        if (!user?.currentLearnerProfile) {
            sessionStorage.setItem(SESSION_STORAGE_KEYS.HAS_SHOW_COMPLETE_INFO_MODAL, 'false');
            setOpenModalCompleteInfo(false);
            return;
        }
        const hasShownModal = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.HAS_SHOW_COMPLETE_INFO_MODAL) || 'false');
        const canShowModal = !user?.currentLearnerProfile?.school?.id && !hasShownModal;
        if (canShowModal) {
            sessionStorage.setItem(SESSION_STORAGE_KEYS.HAS_SHOW_COMPLETE_INFO_MODAL, 'true');
            setOpenModalCompleteInfo(true);
        }
    }, [user?.currentLearnerProfile]);
    useEffect(() => {
        const handleBeforeUnload = () => {
            sessionStorage.setItem(SESSION_STORAGE_KEYS.HAS_SHOW_COMPLETE_INFO_MODAL, 'false');
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    useEffect(() => {
        if (user?.currentLearnerProfile?.lang) {
            void setLanguage(user?.currentLearnerProfile?.lang);
        }
    }, [user?.currentLearnerProfile?.lang]);
    useModal(ModalCompleteInfoLazy, {
        open: openModalCompleteInfo && !(loadingDataWhatNews || loading || loadingListAchievements),
        onClose: setOpenModalCompleteInfo,
        priority: ModalPriority.CompleteInfo
    });
    return <HomePage />;
}

    async function __Next_Translate__getStaticProps__19492fd2110__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/home/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19492fd2110__ as getStaticProps }
  